import Vue from "vue";
import VueApollo from "vue-apollo";
import dotenv from "dotenv";

dotenv.config();

const apiUrl = process.env.VUE_APP_API_URL;
const appUrl = process.env.VUE_APP_URL;

import { apolloClient } from "./whirelab";
import { apolloClientKenmare } from "./kenmare";
import { apolloClientAmer } from "./amer";

const apolloProvider = new VueApollo({
  clients: {
    apolloClientKenmare,
    apolloClientAmer,
  },
  defaultClient: apolloClient,
});

Vue.use(VueApollo);

export default apolloProvider;

export const API_URL = apiUrl;

export const API_URL_KENMARE = "https://recruitment.kenmaremoz.com";
// export const API_URL_KENMARE = "https://kenmare-api-demo.whirelab.com";
// export const API_URL_KENMARE = "http://localhost:3001";

export const API_URL_AMER = "https://amer-api.whirelab.com";
// export const API_URL_AMER = "https://amer-api-demo.whirelab.com";
// export const API_URL_AMER = "http://localhost:3002";

export const API_URL_HIGEST = "https://higest-api.whirelab.com";
// export const API_URL_HIGEST = "https://higest-api-demo.whirelab.com";
// export const API_URL_HIGEST = "http://localhost:3003";

export const APP_URL = appUrl;

export { apolloClient };
